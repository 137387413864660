.mountroot {
  min-height: 750px;
  margin-top: -2px;
  width: 100%;
  }
.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 5px;
    border-radius: 20px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    z-index: 6;
    background-color:#fff;
    box-shadow: 0 0 3px 2px #e9ecef;
  }
  
  .spinner-spinner {
    height: 26px;
    width: 26px;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    border: 2px solid #f89d13;
    border-right-color: transparent;
    border-radius: 20px;
    background: #fff;
  }
  

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes shine {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  @keyframes shine {
    from {
      background-position: 200% 0;
    }
    to {
      background-position: -200% 0;
    }
  }

  
  @media screen and (max-width:600px){
    .mountroot {
      min-height: 480px;
    }
  }

  .loader {
    display: flex;
    align-items: center;
  }
  
  .bar {
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: rgba(255, 255, 255, .5);
    border-radius: 10px;
    animation: scale-up4 1s linear infinite;
  }
  
  .bar:nth-child(2) {
    height: 35px;
    margin: 0 5px;
    animation-delay: .25s;
  }
  
  .bar:nth-child(3) {
    animation-delay: .5s;
  }
  
  @keyframes scale-up4 {
    20% {
      background-color: #222;
      transform: scaleY(1.5);
    }
  
    40% {
      transform: scaleY(1);
    }
  }

  .more-sec-fullscreen {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
  
  }
  /* Dots styling */
.dot {
  width: 8px; /* 4px dots */
  height: 8px;
  margin: 0 6px; /* Spacing between dots */
  background-color: #f89d13; /* Dot color */
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out, colorChange 3s infinite;
}

/* Create bounce effect for each dot */
.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

.dot:nth-child(4) {
  animation-delay: 0.9s;
}

/* Bounce animation */
@keyframes bounce {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px); /* Bounce height */
  }
}