.bg-wt-white {
    line-height: 17px;
    background-color: #fff;
}

/* Font heading */
.h-font2 {
    font-family: "SF UI Text Semibold";
    font-size: 25px;
    color: #222;
    letter-spacing: -1px;
    line-height: 140%;
    margin-bottom: 16px;
}

.h-font3 {
    font-family: "SF UI Text Semibold";
    font-size: 24px;
    line-height: 140%;
    color: #222;
    letter-spacing: -1px;
    margin-bottom: 26px;
}

.h-font4 {
    font-family: 'SF UI Text Medium' !important;
    font-size: 16px;
}

.ab-font-2 {
    font-size: 15px;
}

.ab-font-3 {
    font-size: 14px;
}

/* End */

/* Hover CSS*/


/* Hover CSS*/
.ab-hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background-color: #fff;
    border-color: #1a374d;
}

/* Buttons */


/* end buttons*/

/* Section paddings */
.sec-pt-1 {
    padding-top: 100px !important;
}

.sec-pt-2 {
    padding-top: 50px;
}


.sec-mb-1 {
    margin-bottom: 100px;
}

.sec-mb-2 {
    margin-bottom: 50px !important;
}

.sec-mb-4 {
    margin-bottom: 32px !important;
}

/* paddings */
.p-0 {
    padding: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.ab-p-2 {
    padding: 32px;
}

.p-24 {
    padding: 24px !important;
}

.b-16 {
    border-radius: 16px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 8%);
}

/* end padding */

/* border-radiuse*/
.ab-br-1 {
    border-radius: 26ox;
}

.ab-br-2 {
    border-radius: 16px !important;
}

@media only screen and (min-width: 601px) and (max-width: 997px) {
    .wt-block {
        display: block !important;
    }
}

@media only screen and (max-width: 600px) {
    .bg-wt-white {
        background-color: initial;
    }

    .wt-block {
        display: none;
    }

    .sec-mb-2 {
        margin-bottom: 24px !important;
    }

    /* Section paddings */
    .sec-pt-1 {
        padding-top: 24px !important;
    }

    .sec-pt-2 {
        padding-top: 16px !important;
    }

    .m-mb-0 {
        margin-bottom: 0 !important;
    }

    /* Heading*/
    .h-font2 {
        font-size: 20px;
        line-height: 24px;
    }

    .h-font3 {
        font-size: 20px;
        line-height: 24px;
    }

    .ab-m-p-0 {
        padding: 0 !important;
    }
}

/*End Sections paddings*/

/* Device Breakpoints*/
.w-block {
    display: block;
}

.t-block {
    display: none;
}

.wt-block {
    display: block;
}

.m-block {
    display: none;
}

.d-flex {
    display: flex;
}

.w-100 {
    width: 100%;
}

@media only screen and (min-width: 601px) and (max-width: 997px) {
    .w-block {
        display: none;
    }

    .t-block {
        display: block;
    }

    .m-block {
        display: none;
    }

    .wt-block {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .wt-block {
        display: none;
    }

    .w-block {
        display: none;
    }

    .t-block {
        display: none;
    }

    .m-block {
        display: block;
    }

    /* Backgorund */
    .bg-m-white {
        background-color: #fff;
    }

    .ab-bg-m-grey-1 {
        background-color: #f2f2f2;
    }

    /* End Backgorund */
    .ab-m-px-16 {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}

/* End Device Breakpoints*/



/* Chip Card*/
.chip-ul {
    display: flex;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.chip-ul::-webkit-scrollbar {
    display: none;
}

.chip-li {
    margin-right: 4px;
    margin-bottom: 4px;
}

.chip-li:last-child {
    margin-right: 0px;
}

.chip-card {
    font-family: 'SF UI Text Regular';
    font-size: 14px;
    white-space: nowrap;
    line-height: 0px;
    border: 1px solid transparent;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    color: #222 !important;
    cursor: pointer;
    white-space: nowrap;
    background-color: #f3f3f3;
    position: relative;
    letter-spacing: 0px;
}

.chip-card:hover {
    background-color: #dbdbdb;
    color: #222;
    border-color: transparent;
    box-shadow: none;
}

.chip-card:hover .chip-manu-chip-border {
    visibility: visible;
    opacity: 1;
}

.active-chip-manu {
    color: #fff !important;
    border: 2px solid #222;
    background: #222 !important;
    pointer-events: none;
}

@media screen and (max-width:600px) {
    .chip-ul {
        margin-bottom: 0;
    }

    .chip-card:hover {
        border-color: #1a374d;
        box-shadow: none;
    }

    .chip-card:hover .chip-manu-chip-border {
        opacity: 0;
        border: 1px solid #777279;
    }

    .mb-32 {
        margin-bottom: 8px !important;
    }

    .mt-32 {
        margin-top: 8px !important;
    }

    .active-chip-manu a {
        color: #fff !important;
    }
}


/*for gapping below chip-card*/


/* End Chip Menu */



/* Card with image Menu*/
.menu-ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 8px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    margin-bottom: 24px;
    margin-top: 1px;
}

.card-menu-img-gap {
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    box-shadow: 0 0 7px 1px #e5e5e5;
    padding: 8px;
}

.card-menu-img {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #00000012;
    padding: 8px;
}

.card-menu-img:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    z-index: 99;
    background-color: #fff;
    border-color: #1a374d;
}

.cat-img {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    margin-left: 5px;
}

.cat-img img {
    max-width: 100%;
    max-height: 100%;
}

.cat-name {
    padding: 12px;
    text-align: left;
    font-size: 15px;
    font-family: 'SF UI Text Regular';
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    position: relative;
    color: #222;
}

@media only screen and (max-width: 600px) {
    .menu-ul {
        display: flex;
        flex-wrap: wrap;
    }

    .card-menu-img-gap {
        width: 100%;
    }

    .card-menu-img {
        padding-bottom: 0px;
        padding-left: 0px;
        border: none;
    }

    .chip-card {
        padding: 18px;
        background-color: #e8e8e8;
    }

    .card-menu-img:hover {
        box-shadow: none;
        z-index: 99;
        background-color: #fff;
        border-color: transparent;
    }

    /*Back btn on Mob on below  header Starts */
    .top-strip-for-mob-back {
        background-color: #fff;
        position: sticky;
        width: 100%;
        z-index: 11;
        top: -1px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        font-family: 'SF UI Text Medium';
        /*box-shadow: 0 1px 2px 0 rgb(148 150 159 / 30%)!important;*/
    }

    .top-strip-for-mob-back div:first-child {
        display: flex;
        align-items: baseline;
    }

    .top-strip-for-mob-back div:first-child a {
        cursor: pointer;
    }

    .top-strip-for-mob-back div:first-child a img {
        width: 18px;
        height: 16px;
    }

    .top-strip-for-mob-back p {
        font-size: 14px;
        font-family: 'SF UI Text Medium';
        color: #06c;
    }

    .top-strip-for-mob-back div:first-child a img {
        margin: 16px 4px 16px 16px;
    }

    /*Back btn on Mob on below  header Starts */
}

/* Card Menu*/

/*Product Card used in page brand-details*/
.product-card-1 {
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    height: 480px;
    position: relative;
}

.product_class p {
    color: #222;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 14px;
    font-size: 12px;
}

.product-card-1 a {
    color: inherit;
}

.product-card-1 a:hover {
    text-decoration: none;
}

.product-details-start {
    padding-right: 8px !important;
    padding-bottom: 16px !important;
}

.product-details-start:nth-child(4n) {
    padding-right: 0 !important;
}

.product-img-1 {
    height: 214px;
    display: flex;
    align-items: center;
    padding: 8px;
    padding-bottom: 8;
    border-bottom: 1px solid #ddd;
    width: 100%;
}

.product-img-1:hover {
    border-color: #1a374d;
}

.product-img-1 img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    width: auto !important;
}

.product-text-1 {
    padding: 16px;
    padding-top: 8px;
    position: relative;
}

.product-text-1 .brand {
    font-family: "SF UI Text Light";
    font-size: 14px;
    line-height: 21px;
    color: #222;
    margin-bottom: 6px;
    width: 75% !important;
}

.product-text-1 .view-count {
    font-size: 12px;
    color: #222;
    width: 25% !important;
}

.product-text-1 .name {
    font-size: 14px;
    font-family: 'SF UI Text Regular';
    line-height: 19px;
    color: #454545;
    margin-bottom: 8px;
    width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.product-text-1 .price {
    font-family: "SF UI Text Semibold";
    font-size: 18px;
    color: #222;
    line-height: 26px;
    display: flex;
    align-items: center;
}

.rup-sup {
    color: #b80000;
    font-size: 14px;
    font-family: 'SF UI Text Medium';
}

.rup-sup span {
    font-family: 'SF UI Text Semibold';
}

s {
    font-size: 12px;
    line-height: 14px;
    color: #929292;
    font-family: "SF UI Text Regular" !important;
    margin-right: 4px;
}

.dis-off {
    color: #1aa552 !important;
    font-size: 13px !important;
    line-height: 14px !important;
    margin-left: 4px !important;
    font-family: "SF UI Text Medium" !important;
}

.price-msg {
    font-size: 11px;
    font-family: 'SF UI Text Light';
    line-height: 14px;
    color: #ed7d31;
    margin-top: 4px;
}


/* Loaders */
.loader-height {
    min-height: 363.5px;
}

.is-loading .breadcrumb {
    height: 42px !important;

    background: #f1f1f1 !important;
}

.is-loading .mob-breadcrumb {
    height: 48px !important;
    width: 100% !important;
    margin-bottom: 0px !important;
    background: #f1f1f1 !important;
}



.is-loading .h-name {
    width: 200px;
    background-color: #f2f2f2;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
}

.is-loading .li-name {
    background: #ededed;
    width: 150px;
    height: 100%;
    min-height: 45px;
}

.is-loading .span-loader {
    background: #ededed;
    width: 100px;
    height: 14px;
}

.is-loading .brand {
    background: rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
    height: 14px;
}

.is-loading .product-text-1 {
    padding-left: 0px;
}

.is-loading .view-count {
    background: rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
    height: 14px;
}

.is-loading .name {
    background: rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
    height: 14px;
    margin-bottom: 8px;
}

.is-loading .price {
    background: rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
    height: 14px;
    margin-bottom: 8px;
}

.is-loading:hover .populer-product-img {
    border-bottom: rgba(0, 0, 0, 0.1) !important;
}

.is-loading .price-msg {
    background: rgba(0, 0, 0, 0.1) !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 2.5s shine linear infinite;
    height: 12px;
    margin-bottom: 8px;
}

/*End product Card*/

/* Poster image Css*/
.poster-image-in {
    width: 100%;
}

.poster-image-in img {
    width: 100%;
}

/* End Poster image css*/

/* Inner html css*/
.about-text p {
    font-size: 16px;
    line-height: 140%;
    color: #222;
    font-family: "SF UI Text Light";
}

div.collapsed {
    height: 100px !important;
    overflow: hidden;
    padding: 8px;
}

/* end  Inner html css*/


/* Box-shadow CSS starts*/
.global-shadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, .08) !important;
}

.sec-shadow-1 {
    box-shadow: 0 4px 16px rgba(0, 0, 0, .08) !important;
}

/* Box-shadow CSS Ends*/

/*Brand and dealer section details CSS STARTS*/
/*Banner CSS starts*/
.replace-ab-container {
    padding: 0 !important;
    border-radius: 16px;

}

.border-16 {
    border-radius: 16px;
}





.chip-and-product-detail-cont {
    border-radius: 16px;
    background-color: #fff;
    margin-top: 32px !important;
    padding: 24px;
}

.profile-cover-pick-section {
    position: absolute;
    width: 10rem !important;
    height: 10rem !important;
    border-radius: 8px;
    color: #fff !important;
    background: #fff;
    transition: .4s;
    border: 1px solid #e4e5e7;
    display: flex;
    align-items: center;
    bottom: 19px;
    left: 50px;

}

.profile-cover-pick-section span {
    color: #222;
    font-size: 52px;
    font-weight: 500;
    margin: auto;
}

.profile-cover-pick-section img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    padding: 8px;
}

/*Banner CSS Ends*/

/*Company details details css starts*/
.company-details {
    color: #222;
    font-size: 16px;
    padding: 32px 0;
}


.company-details h3 {
    font-family: "SF UI Text Medium";
    font-size: 24px;
    margin-bottom: 16px;
}

.company-details p {
    font-family: 'SF UI Text Regular';
}

.company-details p span {
    font-family: "SF UI Text Semibold";
    color: #222;
}

.company-details p:last-child {
    color: #2c72d2;
}

/*Company details details END starts*/

/*Card-2 CSS starts*/

.numbers-of-product {
    margin: 6px 0 0;
    font-family: 'SF UI Text Medium';
}

.numbers-of-product p {
    font-family: "SF UI Text Regular";
    font-size: 18px;
    color: #222;
    padding: 0 15px;
}

.numbers-of-product span {
    font-size: 18px;
    color: #222;
    font-family: "SF UI Text Regular";
    margin-left: 4px;
}

.product-detail-container {
    border: 1px solid #ededed;
    border-radius: 12px;
    height: auto;
    transition: .5s;
    height: 100%;
    cursor: pointer;
    position: relative;
}

.product-detail-container:hover {
    border: 1px solid #dbdbdb;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .15), 0 2px 8px rgba(0, 0, 0, .2) !important;
    transform: .5s;
}

.product-details-img {
    margin: auto;
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
}

.product-details-img img {
    max-height: 100%;
    max-width: 100%;
    padding: 6px;
    margin: auto;
}

.product-detail {
    padding: 0px 16px 16px;

}

.product-detail .name {
    font-size: 13px;
    font-family: "SF UI Text Regular";
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px !important;
    letter-spacing: .25px;
}

.product-detail p {
    font-family: "SF UI Text Medium";
    color: #222;
    font-size: 14px;
    line-height: 16.71px;
    margin-bottom: 7px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mt-32 {
    margin-top: 32px;
}

.product-detail-price {
    display: flex;
    color: #222 !important;
    font-family: 'SF UI Text Semibold';
    font-size: 16px;
    line-height: 19px;
}

.product-detail-price p {
    font-size: 15px !important;
    line-height: 19px;
    margin-bottom: 0;
    color: #b80000 !important;
}

.product-detail-price span {
    color: #b80000;
    font-family: 'SF UI Text Semibold';
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
}

.product-detail-price small {
    font-family: 'SF UI Text Semibold' !important;
}

.product-detail-price small s {
    margin-left: 8px;
    font-size: .8rem !important;
    color: #878787 !important;
    font-size: 11px;
    font-family: 'SF UI Text Semibold' !important;
}

.product-detail-price small span {
    color: #1aa552 !important;
    font-size: 12px !important;
    margin-left: 5px !important;
    font-family: 'SF UI Text Semibold' !important;
}

.product-detail .view_count {
    font-size: 12px !important;
    line-height: 14px !important;
    font-family: 'SF UI Text Regular' !important;
}


/*Card-2 CSS Ends*/

@media only screen and (min-width: 601px) and (max-width: 997px) {
    .profile-cover-pick-section {
        width: 150px;
        height: 150px;
    }

    .p-16 {
        padding: 0 16px !important;
    }

    .chip-and-product-detail-cont {
        padding: 24px !important;
        margin-top: 24px !important;
    }

    .company-details {
        padding: 16px 0;
    }

    .chip-ul {
        margin-top: 24px;
    }

    .aptbtnc {
        display: none !important;
    }

    .product-text-1 {
        padding: 14px 14px 0 14px;
    }

    .product-card-1 {
        height: 400px;
    }

    .sec-mb-2 {
        margin-bottom: 24px !important;
    }

    .sec-pt-2 {
        padding-top: 24px !important;
    }

    hr {
        margin: 0 !important;
    }

    .mb-24 {
        margin-bottom: 16px;
    }
}



@media only screen and (max-width: 600px) {
    .chip-and-product-detail-cont {
        margin-top: 12px !important;
        padding: 8px;
        border-radius: 0;
        box-shadow: none !important;
    }

    .is-loading .breadcrumb {
        height: 48px !important;
    }

    .company-details p:last-child {
        margin-bottom: 13px !important;
    }

    .profile-cover-pick-section {
        width: 7rem !important;
        height: 6rem !important;
        bottom: 175px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .profile-cover-pick-section img {
        padding: 12px;
    }

    .company-details {
        font-size: 12px;
        padding: 0 16px;
        margin-bottom: 24px;
    }

    .company-details h3 {
        margin-bottom: 16px;
    }

    .company-details h3 {
        font-size: 20px;
        margin-top: 32px;
        font-family: 'SF UI Text Semibold';
    }

    .company-details p {
        margin-bottom: 6px;
        font-size: 14px;
    }

    .product-detail-container {
        border: none;
        box-shadow: 0 0 0 1px #0000000d, 0 2px 3px #0000001a !important;
        background-color: #fff;
        border-radius: 8px;
        padding-bottom: 8px;
    }

    .product-detail-price small span {
        margin-left: 3px !important;
    }

    .product-details-img {
        height: 170px;
    }

    .product-detail p:first-child {
        font-family: "SF UI Text Regular";
        line-height: 17px;
        color: #222;
        margin-bottom: 2px;
    }

    .is-loading .product-detail p:first-child {
        margin-top: 8px;
    }

    .product-detail-price p {
        font-family: 'SF UI Text Bold';
    }

    .product-detail p:nth-child(2) {
        margin-bottom: 6px;
        font-family: 'SF UI Text Regular';
    }

    .chip-li {
        margin-bottom: 8px;
    }

    .chip-li {
        margin-right: 8px;
    }

    .numbers-of-product h3 {
        font-size: 18px;
        margin-bottom: 0 !important;
    }

    .numbers-of-product p {
        font-family: "SF UI Text Medium";
        font-size: 20px;
    }

    .product-detail-price small span {
        font-size: 14px !important;
        font-family: 'SF UI Text Medium';
    }

    .product-detail-price span {
        font-family: 'SF UI Text Bold';
        font-size: 16px;
        line-height: 17px;
    }

    .size-font {
        font-family: 'SF UI Text Regular' !important;
        font-size: 11px !important;
        color: inherit !important;
    }


    .product-details-img img {
        padding: 6px 12px;
        margin: auto;
    }

    .product-detail-price {
        font-size: 14px;
        line-height: 17px;
    }

    .product-detail-price small s {
        font-size: 11px;
        line-height: 14px;
        color: #546e7a;
        font-family: "SF UI Text Regular" !important;
    }

    .product-card-1 {
        box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 2px 3px rgb(0 0 0 / 10%);
        border: none !important;
        border-radius: 8px;
        height: 315px;
    }

    .product-text-1 {
        padding: 8px;
    }

    .product-details-start {
        padding: 0 8px 8px 0 !important;
    }

    .product-details-start:nth-child(2n) {
        padding-right: 0 !important;
    }

    .aptbtnc {
        display: none !important;
    }

    .product-img-1 {
        height: 150px;
    }

    .product-text-1 .name {
        font-size: 14px;
        font-family: 'SF UI Text Regular';
        color: #222;
    }

    .rup-sup {
        font-size: 15px;
        font-family: 'SF UI Text Semibold';
    }
}




/*Brand and dealer section details CSS Ends */


/* Button CSS starts*/
.btn-1 {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.btn-1 {
    color: #ffffff;
    background: #1A374D;
    border: 1px solid #1A374D;
    padding: 0.75rem 1.25rem;
    font-size: 16px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    font-family: 'SF UI Text Bold';
}

.btn-1:enabled:hover {
    background: #112534;
    color: #ffffff;
    border-color: #2563eb;
}

.btn-1 i {
    margin-left: 8px;
}

/* Button CSS starts*/

/*Start-category-type-popup*/
.category-container {
    width: 460px;
}

.ul-category {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
    gap: 8px;
    padding: 0;
}

.cat-card {
    width: 100%;
    height: 115px;
    border: 1px solid #c8c6c6;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    padding: 8px;
    transition: .3s;
    display: block;
    background-color: #fff;
}

.cat-card:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background-color: #fff;
    border-color: #1a374d;
}

.cat-img {
    width: 60px;
    height: 47px;
    position: relative;
    display: flex;
    margin: auto;
}

.cat-img img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}

.cat-text p {
    font-size: 13.5px;
    line-height: 123%;
    font-family: "SF UI Text Regular";
    font-stretch: normal;
    color: #222;
    text-align: center;
    width: 105px;
    margin: auto;
    margin-top: 6px;
}

.close-modal {
    display: flex;
    position: absolute;
    right: 8px;
    top: 10px;
    transition: 0.3s;
    cursor: pointer;
    z-index: 99;
    padding: 4px;
    border-radius: 20px;
    background-color: #fff;
}

.close-modal img {
    width: 17px;
    height: 17px;
    margin: auto;
}

.m-pop-header {
    padding: 24px 24px 0;
    text-align: left;
    margin-bottom: 24px;
}

.m-pop-header h4 {
    font-family: "SF UI Text Bold" !important;
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 8px;
}

.m-pop-header p {
    line-height: 1.5;
    margin: 0;
}

@media only screen and (max-width: 601px) {
    .category-container {
        width: 100%;
    }

    .ul-category {
        grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
        padding: 0 16px;
    }

    .cat-card {
        height: 94px;
    }

    .cat-text p {
        font-size: 12px;
        line-height: 150%;
        margin-bottom: 0px;
        font-family: "SF UI Text Regular";
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }
}

/*End-category-type-popup*/

/*Brand and dealer list page section starts*/
.brand-banner-heading-li {
    position: relative;
    top: 37%;
    text-align: center;
}

.brand-banner-heading-li h2 {
    font-family: "SF UI Text Semibold";
    margin: 0;
}

.brand-banner-heading-li p {
    font-size: 22px;
}

.banner-container-li {
    height: 300px;
    margin-bottom: 32px;
    background-color: #e8f0ff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}

.brand-banner-height {
    height: 300px;
}


.brand-logo-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 4px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    margin-bottom: 0px;
    margin-top: 0px;
}

.brand-img {
    width: 100%;
    height: 100px;
    border-radius: 8px;
    border: 1px solid #cfd8dc;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0 1px 3px #bdbdbd26;
    padding: 7px;
}

.brand-img:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background-color: #fff;
    border-color: #1a374d;
}

.brand-img img {
    max-width: 100%;
    max-height: 100%;
    padding: 18px;
}

.numbers-of-products {
    margin-bottom: 32px;
}

.numbers-of-products p {
    color: #222;
    font-size: 18px;
    font-family: "SF UI Text Regular" !important;
    margin: 0;
}

.numbers-of-products span {
    font-size: 18px;
    font-family: "SF UI Text Regular" !important;
    color: #222;
    margin-left: 4px;
}

.numbers-of-products {
    margin-bottom: 16px;
}

@media only screen and (min-width: 601px) and (max-width: 997px) {
    .banner-container-li {
        height: 280px;
        margin-bottom: 24px;
        background-color: #e8f0ff !important;
        background-repeat: no-repeat !important;
        border-radius: 0 !important;
    }

    .brand-logo-card {
        padding: 24px;
    }

    .numbers-of-products {
        padding: 24px 0 0 24px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 600px) {
    .cat-card:hover {
        box-shadow: none;
        border-color: #c8c6c6 !important;
    }

    .brand-logo-card {
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
        gap: 4px;
        overflow: hidden;
        overflow-x: auto;

    }

    .brand-img img {
        padding: 8px;
    }

    .brand-banner-heading-li {
        top: 37%;
    }

    .brand-banner-heading-li h2 {
        font-size: 16px;
    }

    .brand-banner-heading-li p {
        font-size: 13px;
    }

    .sec-shadow-1 {
        box-shadow: none !important;
    }

    .numbers-of-products {
        margin-bottom: 16px;
    }

    .ul-category {
        gap: 8px;
        margin-bottom: 24px !important;
    }

}

/*Brand and dealer list page section Ends*/


/*BreadCrumb CSS Starts here*/
.breadcrumb-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
    padding: 12px;
    background: none;
}

.breadcrumb-list li {
    font-family: 'SF UI Text Regular';
    font-size: 0.75rem !important;
    color: #222;
    cursor: pointer;
}

.breadcrumb-list li:hover {
    text-decoration: underline;
}

.breadcrumb-list li.active {
    text-decoration: none;
    pointer-events: none;
    color: #222222d4 !important;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem !important;
    cursor: pointer;
}

.breadcrumb-item.active {
    pointer-events: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem !important;
    color: #6c757d !important;
    content: "/" !important;
}

.next-btn {
    font-size: 13px;
    padding: 4px 20px;
    border: 1px solid #222;
    background-color: #222;
    font-family:"SF UI Text Regular";
    border-radius: 19px;
    color: #fff;
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
}

.next-btn i {
    margin-top: 2px;
    margin-left: 3px;
}

.next-conatiner-popover {
    background-color: #f5f5f7 !important;
    border: 1px solid #f3f3f3 !important;
    border-radius: 22px !important;
    padding: 0.7rem !important;
    box-shadow: 0 1px 3px #24272c26 !important;
    z-index: 9999 !important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding: 0px !important;
}

.next-conatiner-popover .p-overlaypanel .p-overlaypanel-content {
    padding: 1rem !important;

}

.next-conatiner-popover .p-overlayPanell {
    color: #495057;
    border: 0 none;
    border-radius: 27px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.next-container p i {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    margin-top: 2px;
}

.next-container {
    height: fit-content;
    display: grid;
}

.next-container button {
    padding: 8px 14px;
    border-radius: 22px;
    border: 1px solid #d6d6d6 !important;
    background-color: #fff;
    color: #222;
    margin-bottom: 8px;
    font-size: 13px;
    font-family: 'SF UI Text Medium';
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    align-items: center;
}

.next-container button span {
    display: flex;
    background-color: #06c;
    color: #fff;
    border-radius: 50%;
    aspect-ratio: 8/8;
    /* padding: 3px 5px 2px; */
    font-size: 10px;
    font-family: 'SF UI Text Medium';
    margin-left: 7px;
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    height: 20px;
}

.next-container button:last-child {
    margin-bottom: 0px;
}

.next-container button:hover {
    border-color:#8b86fe !important;
}

.back-btn {
    font-size: 13px;
    padding: 0 18px;
    font-family: 'SF UI Text Regular';
    border: 1px solid transparent;
    color: #222;
    position: relative;
    background-color: #fff;
}

.back-btn:hover {
    text-decoration: underline;
}

.p-overlaypanel:before {
    display: none;
}

.p-overlaypanel:after {
    display: none;
}

/*BreadCrumb CSS Ends here*/

/*------------Notifications--------------*/
.notificationdiv {
    position: absolute;
    top: 102px;
    background: #fff;
    width: 560px;
    right: 10.5%;
    text-align: left;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 15%) !important;
    z-index: 9999;
    border-radius: 8px;
    max-height: 80vh;
    overflow: auto;
    padding: 24px 16px !important;
    display: block;
}

.NotofyHeadING h4 {
    font-family: "SF UI Text Semibold";
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 12px;
}

.REcentdata p {
    font-family: "SF UI Text Semibold";
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 6px;
}

.NOnewNOtific p {
    font-family: "SF UI Text Regular";
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
}

.showtextDATA {
    display: flex;
    align-items: center;
    padding: 10px 0.25rem;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
}

.showtextDATA:hover {
    background: #f2f2f2;
}

.noti-pro-img {
    font-size: 20px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #1a374d;
    padding: 9px 15px;
    text-align: center;
}

.SHOWIcons {
    position: relative;
    height: 50px;
    width: 50px;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    border-radius: 25px;
    overflow: hidden;
}

.SHOWIcons img {
    width: 100%;
    height: 100%;
}

.SHOwMEssaGe {
    padding-left: 12px;
    width: 85%;
}

.SHOwMEssaGe p {
    font-family: 'SF UI Text Light';
    font-size: .96rem;
    line-height: 1.3;
}

.SHOwMEssaGe .MesiBold {
    font-family: 'SF UI Text Regular' !important;
    text-transform: uppercase;
    font-size: .96rem !important;
    color: #000 !important;
}

.SHOwMEssaGe p span {
    font-family: 'SF UI Text Light';
    color: #1d70aa;
    font-size: .85rem;
}

.invisible-h {
    display: flex;
    align-items: center;
}

.dot {
    height: 12px;
    width: 12px;
    background-color: #1976f2;
    border-radius: 50%;
    display: inline-block;
}

.see-all {
    width: 100%;
    margin: auto;
    display: flex;
}

.see-all button {
    font-size: 12px;
    line-height: 14px;
    color: #222;
    padding: 0.7rem 2rem;
    cursor: pointer;
    font-family: "SF UI Text Semibold";
    text-align: center;
    background: #f2f2f2;
    border: 1px solid #cecece;
    box-sizing: border-box;
    border-radius: 4px;
    width: 50%;
    margin: auto;
}

.REaded span {
    color: #838383 !important;
}

.REaded p {
    color: #838383 !important;
}

.REaded .MesiBold {
    color: #838383 !important;
}

.fa-bell-slash {
    font-size: 2rem;
}

.NOnewNOtific p {
    margin-top: 8px;
    font-size: 14px;
    font-family: "SF UI Text Light";
}

/*------------Notifications END--------------*/


/*Paginator CSS Starts here*/
.pagenetar-c {
    position: relative !important;
}

.pagenetar-c .w-block span {
    font-family: 'SF UI Text Regular';
}

.p-paginator {
    background: none !important;
    margin-bottom: 16px;
}

.p-paginator-page.p-highlight {
    background: #EFF6FF !important;
    min-width: 2rem !important;
    height: 2rem !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: #000 !important;
    background: #e5e5e5 !important;

}
.p-paginator .p-paginator-pages .p-paginator-page{
    font-family: "SF UI Text Regular" !important;
    font-size: 14px !important;
}
.p-paginator-page:not(.p-highlight):hover {
    background: #e9ecef !important;
    border-color: transparent !important;
    color: #000 !important;
    border-radius: 8px !important;
    height: 2rem !important;
    min-width: 2rem !important;
}

.p-link:focus,
.p-paginator-page.p-highlight {
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 8px !important;
    border: 1px solid #b7b7b7 !important;
    box-shadow: none !important;
    font-family: 'SF UI Text Medium' !important;
}

.p-paginator-page,
.p-paginator-next,
.p-paginator-last,
.p-paginator-first,
.p-paginator-prev,
.p-paginator-current {
    height: 2rem !important;
    min-width: 2rem !important;
    color: #000 !important;
    font-family: 'SF UI Text Medium' !important;
}

.p-disabled {
    font-family: 'SF UI Text Medium' !important;
    pointer-events: none;
}

@media screen and (max-width: 600px) {
    .p-paginator {
        margin-bottom: 0px;
    }

    .sb-button {
        margin: 0.1em !important;
    }

    .product-text-1 .brand {
        font-size: 15px;
        font-family: 'SF UI Text Medium';
        margin-bottom: 0px;
    }

    .p-paginator {
        background-color: transparent !important;
        padding: 0 16px !important;
        display: block !important;
        text-align: center !important;
    }

    .pages-of-paginator {
        top: 10% !important;
        color: #000 !important;
        font-family: 'SF UI Text Medium' !important;
    }

    .rup-sup {
        font-size: 16px !important;
        font-family: 'SF UI Text Semibold' !important;
    }

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last,
    .p-paginator-page {
        color: #000 !important;
        min-width: 1.5rem !important;
        height: 1.5rem !important;
        font-family: 'SF UI Text Medium' !important;
    }

    .p-link:focus,
    .p-paginator-page.p-highlight {
        background-color: #e5e5e5 !important;
        color: #000 !important;
        border-radius: 8px !important;
        border: 1px solid #b7b7b7 !important;
        box-shadow: none !important;
    }

    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background-color: transparent !important;
    }

}

/*Paginator CSS Ends  here*/
/*validation error*/
.validation-error {
    color: #f31f1f;
    padding-left: 5px;
    font-size: 0.6rem;
    font-style: normal;
    font-stretch: normal;
    margin: auto 0;
    display: block;
    width: 100%;
    position: absolute;
    line-height: 1;
}

/*end*/

/* toast */

#toast {
    visibility: hidden;
    max-width: 50px;
    height: 50px;
    margin: auto;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    position: fixed;
    z-index: 999999;
    left: 0;
    right: 0;
    top: 80px;
    font-size: 17px;
    white-space: nowrap;
}

#toast #img {
    width: 50px;
    height: 50px;
    float: left;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    background-color: #111;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
}

#toast #img i {
    color: #1aa552;
    font-size: 24px;
}

#toast #desc {
    color: #fff;
    padding: 16px;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    display: flex;
    justify-content: center;
}

#toast.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 2s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s, shrink 0.5s 4s, fadeout 0.5s 4.5s;
}

@-webkit-keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 80px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 80px;
        opacity: 1;
    }
}

@-webkit-keyframes expand {
    from {
        min-width: 50px
    }

    to {
        min-width: 350px
    }
}

@keyframes expand {
    from {
        min-width: 50px
    }

    to {
        min-width: 350px
    }
}

@-webkit-keyframes stay {
    from {
        min-width: 350px
    }

    to {
        min-width: 350px
    }
}

@keyframes stay {
    from {
        min-width: 350px
    }

    to {
        min-width: 350px
    }
}

@-webkit-keyframes shrink {
    from {
        min-width: 350px;
    }

    to {
        min-width: 50px;
    }
}

@keyframes shrink {
    from {
        min-width: 350px;
    }

    to {
        min-width: 50px;
    }
}

@-webkit-keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0px;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        top: 30px;
        opacity: 1;
    }

    to {
        top: 0px;
        opacity: 0;
    }
}

/*  share button css */

.sb-group share-button {
    margin: auto !important;
}

.sb-button {
    margin: 0.3125em;
}

.sb-wrapper {
    color: #fff !important;
    height: 24.23px !important;
    width: 24.23px !important;
    min-width: 0 !important;
    margin: auto;
    margin-right: 4px;
    border-radius: 50% !important;

}

.sb-wrapper .sb-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.sb-wrapper .sb-icon {
    text-align: center !important;
    width: 15px !important;
    height: 15px !important;
    font-size: 14px !important;
    min-width: 0em !important;
    min-height: 0px !important;
    box-shadow: none !important;
}

.sb-whatsapp {

    border: 1px solid #25d366 !important;
    background-color: #25d366 !important;

    color: #fff !important;
    height: 24.23px !important;
    width: 24.23px !important;
    min-width: 0 !important;
    margin: auto;
    margin-right: 4px;
    border-radius: 50% !important;
}

.sb-whatsapp:hover {
    border: 1px solid #636363 !important;
}

.sb-facebook {
    color: #fff !important;
    background-color: #4267b2 !important;
    padding-top: 4px !important;
}

.sb-facebook:hover {
    border: 1px solid #636363 !important;
}

.sb-linkedin:hover {
    border: 1px solid #636363 !important;
}

.sb-pinterest:hover {
    border: 1px solid #636363 !important;
}

.sb-linkedin {
    color: #fff !important;
    background-color: #006fa6 !important;
    padding-top: 4px !important;
}

.sb-pinterest {
    color: #fff !important;
    background-color: #bd091d !important;
    padding-top: 4px !important;
}

.ab-modal-heading {
    padding: 0;
    margin-bottom: 24px;
}

.ab-modal-heading h3 {
    margin-bottom: 0px;
    font-size: 23px;
    text-align: center;
    font-family: 'SF UI Text Regular';
    color: #222;
}

.ab-modal-heading p {
    margin-top: 4px;
    text-align: center;
    font-size: 13px;
    font-family: 'SF UI Text Regular';
    color: #7030a0;
}

.ab-modal-body {
    padding: 0px 0px;
    min-height: 215px;
}

.ab-modal-body-ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    padding-right: 16px;
}

.ab-modal-body-card {
    width: 130px;
    height: 120px;
    background: #f9f9f9;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 8px;
    transition: 0.3s;
    display: block;
    letter-spacing: 0px;
}

.ab-modal-body-img {
    width: 64px;
    height: 69px;
    position: relative;
    display: flex;
    margin: auto;
}

.ab-modal-body-img img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    mix-blend-mode: multiply;
}

.ab-modal-dept-name {
    font-size: 13px;
    line-height: 123%;
    font-family: "SF UI Text Regular";
    color: #222;
    text-align: center;
    width: 100%;
    margin: auto;
    margin-top: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ab-modal-dept-name p {
    color: #222 !important;
}

.ab-modal-body-card:hover {
    box-shadow: 0 3px 6px #211e2233;
    background-color: #fff;
    border-color: #1a374d;
}

.ab-modal-footer {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.ab-modal-footer button {
    border: none;
    background-color: transparent;
    color: #06c !important;
    font-family: 'SF UI Text Medium';
}

.ab-modal-footer button:first-child {
    color: #484848 !important;
}


.ab-project-model .ui-dialog .ui-dialog-content {
    padding: 24px;
}

.project-body {
    width: 660px
}

.ab-project-model .ab-modal {
    padding: 0;
}

.ab-project-model .ab-modal-body {
    padding: 0;
    min-height: 88px;
}

.ab-project-model .ab-modal-heading {
    padding: 0px 0px 24px;
}

.ab-project-model .ab-modal-heading h3 span {
    font-size: 13px;
    font-family: 'SF UI Text Regular';
}

.ab-p-project-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    box-sizing: border-box;
    align-items: center;
    padding: 0;
    margin-bottom: 0px;
    margin-top: 1px;
    overflow: initial !important;

}

.ab-p-project-list li {
    height: 100%;
}

.ab-p-project-card {
    border-radius: 11px;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.21);
    border: 1px solid #d2d2d2;
    display: block;
    padding: 16px 24px;
    height: 100%;
    cursor: pointer;
}

.ab-p-project-card:hover {
    border: 1px solid #1a374d;
}

.active-ab-p-project-card {
    border: 1px solid #ffbf56;
    background: #ffcf80;
}

.ab-p-project-card span {
    font-size: 13px;
    color: #000;
    display: block;
    margin-bottom: 2px;
    font-family: "SF UI Text Medium";
}

.ab-p-project-card h3 {
    font-size: 13px;
    font-family: 'SF UI Text Semibold';
    margin-bottom: 6px;
    color: #222;
    text-align: left;
}

.ab-p-project-card p {
    font-size: 12px;
    font-family: "SF UI Text Regular";
}

.new-project-create-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    min-height: 89.5px;
    padding: 16px 24px;
}

.ab-add-project-box {
    margin: auto;
    display: grid;
    text-align: center;
    cursor: pointer;
}

.ab-add-project-box i {
    font-size: 30px;
    color: #06c;
}

@media only screen and (max-width: 600px) {
    .ab-modal-body-card:hover {
        background-color: #f3f3f3;
        border-color: #dbdbdb;
    }

    .ab-project-model .ui-sidebar {
        padding: 2em 1rem;
        max-height: 90vh;
        height: fit-content;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .project-body {
        width: 100%;
    }

    .ab-modal-heading h3 {
        font-size: 20px;
        font-family: 'SF UI Text Semibold';
        display: grid;
        align-items: center;
        color: #7030a0;
    }

    .ab-modal-heading h3 span {
        font-size: 14px;
        font-family: 'SF UI Text Regular';
    }

    .ab-modal-body-ul {
        grid-template-columns: repeat(3, 1fr);
        padding: 0px;
    }

    .ab-modal-body-card {
        width: 111px;
        height: 100%;
        padding: 8px;
    }

    .close-popup {
        right: 0px !important;
        top: -6px !important;
    }

    .ab-modal-footer {
        padding: 16px 16px;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        gap: 8px;
    }

    .ab-modal-footer button {
        height: 41px;
        padding: 10px 32px;
        position: relative;
    }

    .ab-modal-footer button:first-child {
        background-color: #fff !important;
        border: 1px solid #dcdcdc !important;
        border-radius: 26px !important;
        color: #222 !important;
        font-size: 13px;
        font-family: "SF UI Text Medium";

    }

    .ab-modal-footer button:last-child {
        background: #222 !important;
        border: 1px solid #222 !important;
        color: #fff !important;
        border-radius: 26px !important;
        font-size: 13px;
        font-family: "SF UI Text Medium";
    }

    .ab-modal-dept-name {
        font-size: 11.5px;
        line-height: 1.2;
        font-family: "SF UI Text Medium";
        width: 76%;
        text-overflow: ellipsis;
        white-space: pre-wrap;

    }
}

.web-display-server {
    display: block;
}

.mobile-display-server {
    display: none;
}

@media only screen and (max-width: 600px) {
    .web-display-server {
        display: none !important;
    }

    .mobile-display-server {
        display: block;
    }
}

.btnPriCEWise {
    border: none;
    font-size: 14px;
    white-space: nowrap;
    font-family: 'SF UI Text Medium';
    font-weight: 500;
    font-stretch: normal;
    font-variant: normal;
    font-style: normal;
    color: #1A374D;
    background: transparent;
    outline: none;
    transition: .3s;
    padding: 0.3rem .8rem .3rem .8rem;
    border-radius: 4px;
}

.btnPriCEWise:hover {
    text-decoration: underline;

}

.btnPriCEWise span {
    color: #06c !important;
}

.btnPriCEWise::after {
    color: #1d70aa !important;
}

.btnPriCEWisedropdown {
    cursor: pointer;
    padding: 5px 40px 5px 20px !important;
    text-align: left;
    font-size: .95rem !important;
    font-family: 'SF UI Text Regular' !important;
    font-weight: 500;
    font-stretch: normal;
    font-variant: normal;
    font-style: normal;
    margin: .3rem auto !important;
    font-size: .9rem !important;
}

/*  Popup for go next step */

.contentcongratspopup {
    max-width: 43.75rem;
}

.contentcongratspopup .row .col-6:nth-child(1) img {

    width: 70px;
}

.contentcongratspopup .row .col-6:nth-child(2) img {
    height: 24px;
    cursor: pointer;
    position: relative;
    left: 3px;
}

.createditembuttonsearch {
    background-color: #1A374D !important;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-transform: capitalize !important;
    border: 1px solid #1A374D !important;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer !important;
    font-family: 'SF UI Text Medium';
    font-variant: normal;
    font-style: normal;
}

.createditembuttonsearch:hover {
    border-color: #1A374D;
    background-color: #fff !important;
    color: #222;
}

.contentcongratspopup .ImageMobPop {
    text-align: center;
    padding-top: 32px;
}

.contentcongratspopup .ImageMobPop img {
    width: 70px;
}

.createditem-headingsearch h2 {
    font-family: 'SF UI Text Medium';
    font-weight: 500;
    color: #222;
    font-size: 28px;
    text-align: center;
    padding: 0 0 12px;
}

.createditem-headingsearch h4 {
    font-family: 'SF UI Text Regular';
    color: #222;
    font-size: 15px;
    text-align: center;
    padding-bottom: 30px;
}

.BackOffCross img {
    width: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.crossdealr {
    text-align: right;
    padding: 15px 15px 0;
}

.crossdealr img {
    width: 24px;
    cursor: pointer;
    position: relative;
    left: 3px;
}

.bottomless {
    margin-bottom: -4px;
}

.createditem-para {
    background: #585858;
    border: 1px solid #a2a2a2;
    padding: 4px 15px;
    margin-right: 0.3rem;
    justify-content: center;
    margin-bottom: .3rem;
}

.createditem-para p:first-child {
    font-family: 'SF UI Text Regular';

    color: #fff;
    font-size: .9rem;
    text-align: center;
    margin-bottom: -4px;
}

.createditem-para p:nth-child(2) {
    font-family: 'SF UI Text Light';
    color: #fff;
    font-size: .8rem;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .ab-p-project-card {
        background: #fff;

    }

    .ab-p-project-card h3 {
        font-size: 15px;
        text-align: left;
    }

    .ab-p-project-card p {
        font-family: "SF UI Text Medium";
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 128px;
    }

    .new-project-create-box {
        background: #fff;
    }

    .ab-p-project-card span {
        font-size: 14px;
        font-family: "SF UI Text Medium";
    }
}

.ab-project-ul {
    padding: 0;
    padding-top: 0px;
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
    gap: 6px;
    box-sizing: border-box;
}

.hometype-card {
    border: 1px solid #ffbf56;
    background: #fff;
    border-radius: 6px;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.hometype-card:hover {
    background-color: #fc9a1814;
    color: #222;
}

.hometype-card span {
    font-family: 'SF UI Text Semibold';
    font-size: 14px;
}

.hometype-card img {
    height: 25px;
    margin-right: 8px;
}

.project-footer {
    margin-top: 16px;
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.active-hometype-card {
    border-color: #222;
    color: #222 !important;
    background: #FFcf80;
    pointer-events: none;
}

.ab-cover {
    width: calc(100%);
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 16px !important;
    border: 1px solid transparent;
}

.ab-cover img {
    height: 100%;
    max-width: 100%;
    border-radius: 22px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 3px;
}

.p-overlaypanel:before {
    display: none;
}

.p-overlaypanel:after {
    display: none;
}

.invite-strip-sec {
    width: 100%;
}

.invite-strip {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25rem;
}

.invite-strip h3 {
    margin-bottom: 0;
    font-weight: 700;
    padding: 12px 0;
    font-size: 17px;
    font-family: "SF UI Text Regular";

}

.invite-strip h3 span {
    font-family: "SF UI Text Regular" !important;
    font-size: 12px;
    color: #222;
}

.invite-strip-heading {
    display: flex;
    align-items: center;

}

.invite-strip-heading img {
    height: 54px;
    margin-right: 20px;
}

.invite-strip ul {
    display: flex;
    gap: 16px;
    margin-bottom: 0;
}

.invite-strip ul li {
    font-size: 14px;
    color: #3b82f6;
    padding-right: 16px;
    display: flex;

}

.invite-strip ul li img {
    margin-right: 12px !important;
    height: 20px;
    width: 20px;
    margin: auto;
    display: flex;

}

.invite-strip ul li span {
    color: #222;
}

.invite-strip button {
    color: #222;
    z-index: 9;
    position: relative;
    border: none;
    background: #f5f5f5;
    font-size: 19px;
    font-family: 'SF UI Text Regular';
    padding: 16px 58px;
    border-radius: 50px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 1px 13px rgba(36, 39, 44, 0.37);
}

.invite-strip button span {
    font-size: 12px;
    font-family: 'SF UI Text Regular';
}


@media screen and (min-width: 1400px)and (max-width: 1498px) {
    .invite-strip .exhibit-joinButton{
        padding: 0.688rem 1.25rem;
    }
    .invite-strip-sec {
        width: 100%;
    }

    .invite-strip button {
        padding:6px 58px;

    }
    .invite-strip ul li{
        padding-right:13px;
    }
    .invite-strip li img {
        margin-right: 12px;
        height: 18px;
        width: 18px;
        margin: auto;
    }
}

@keyframes components_rotate {
    0% {
        transform: translate(-50%, -50%) scale(1.4) rotafte(0turn);
    }

    100% {
        transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
    }
}

@media only screen and (max-width: 600px) {

    .invite-strip-sec {
        width: calc(100% - 115px);
        margin: auto;
    }

    .invite-strip-sec-mb {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .invite-strip-sec-mt {
        margin-bottom: 24px;
        margin-top: 24px;
    }

    .invite-strip-heading {
        display: grid;
        margin-bottom: 16px;

    }

    .invite-strip-heading img {
        margin: auto;
    }

    .invite-strip {
        background-color: #fff;
        --offset: 2px;
        box-shadow: inset 0 0 1px 0 rgba(72, 68, 68, .7);
        border-radius: 3.125rem;
        overflow: hidden;
        width: -moz-fit-content;
        color: #fff;
        font-size: .875rem;
        font-family: Roboto-Bold, Helvetica Neue, Arial, sans-serif;
        position: relative;
        text-align: center;
        color: #222;
        padding: 0;
        cursor: pointer;
        margin: auto;
        height: 68px;
    }

    .invite-strip ul {
        display: none;
    }

    .invite-strip button {
        font-size: 17px;
        font-family: 'SF UI Text Semibold';
        padding: 20px 0px;
        width: 100%;
    }

    .invite-strip button span {
        font-size: 10px;
        font-family: 'SF UI Text Medium';
    }

    .next-container button {
        font-size: 13px;
        font-family: 'SF UI Text Semibold';
    }
}


/* Product re */

.recently-viewed-product-card {
    background: #fff;
    border: 1px solid transparent;
    width: 294px;
    min-width: 294px;
    margin-right: 16px;
    height: 388px;
}

.brand-text-over-flow-hidden p {
    color: #222;
    font-size: 16px;
    font-family: "SF UI Text Regular";
}

.product-img {
    cursor: pointer;
    height: 214px;
    display: flex;
    align-items: center;
    padding: 8px 8px 0;
}

.product-img:hover {
    border-color: #1a374d;
}

.product-img img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.product-text {
    padding: 8px 16px 16px;
    position: relative;
}

.brand-text-over-flow-hidden {
    display: flex;
    justify-content: space-between;
}

.product-text .name {
    font-size: 15px;
    font-family: 'SF UI Text Light';
    line-height: 19px;
    color: #222;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-text .view-count {
    font-size: 12px;
    color: #546e7a;
}

.product-text .price {
    font-family: "SF UI Text Regular";
    font-size: 15px;
    color: #b80000;
    line-height: 26px;
    display: flex;
    margin-bottom: 0 !important;
}

.mob-pric {
    font-size: 13px;
    line-height: 21px;
    color: #222 !important;
    margin-right: 3px;
    text-transform: capitalize;
}

.rup-sig {
    font-family: "SF UI Text Regular";
    font-size: 13px;
    line-height: 26px;
}

.product-text .price-msg {
    font-size: 11px;
    font-family: 'SF UI Text Light';
    line-height: 14px;
    color: #222;
    margin-top: 4px;
}

.pp s {
    font-size: 14px !important;
    font-family: "SF UI Text Regular";
    margin-left: 8px;
}

.dis-off {
    margin-right: 8px !important;
    color: #1aa552 !important;
    font-size: 13px !important;
    line-height: 12px !important;
    margin-left: 4px !important;
    margin-top: 4px;
}

.product-container {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
}

.add-product-popup-heading h3 img {
    height: 26px;
    margin-bottom: 3px;
    margin-right: 8px;
}

.professional-works {
    border: 1px solid #f2f2f2;
    border-radius: 40px;
    padding: 12px 8px 12px 8px;
    height: 48px;
    display: flex;
}

.professional-works:hover {
    box-shadow: 0 3px 6px rgb(33 30 34 / 20%);
    background-color: #fff;
    border-color: #1a374d;
}

.active-professional-work {
    box-shadow: 0 3px 6px rgba(33, 30, 34, 0.2);
    background-color: #f3f3f3;
    border-color: #1a374d;
    pointer-events: none;
}

.professional-options-body .professional-works div {
    font-family: "SF UI Text Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #222;
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.professional-works div {
    font-family: "SF UI Text Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #222;
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.blank-circle {
    width: 22px;
    height: 22px;
    border: 1px solid #9b9b9b;
    border-radius: 50%;
    margin-right: 5px;
}

.pro-type {
    width: calc(100% - 40px);
}

.professional-works:hover .blank-circle {
    border-color: #c4562c !important;
}

.active-professional-work:hover .blank-circle {
    border-color: #fff !important;
}

.active-professional-work:hover .active-circle {
    border-color: #fff !important;
}

.active-circle:hover {
    border-color: white !important;
}

.active-circle {
    border-color: #1a374d;
    background-color: #fff;
}

.active-circle::after {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    border: solid #1a374d;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-top: 2.2px;
    margin-left: 6px;
}

.ct-icon-h40 {
    height: 40px !important;
}

.mt-8px {
    margin-top: 8px;
}

@media only screen and (min-width: 1400px) and (max-width: 1498px) {
    .ab-cover {
        width: calc(100% - 100px) !important;
        margin: auto;
        margin-top: 8px;
    }

    .recently-viewed-product-card {
        width: 236px;
        min-width: 236px !important;
    }
}

@media only screen and (max-width: 600px) {
    .recently-viewed-product-card {
        width: 136px;
        min-width: 151px;
    }
}

@media screen and (min-width: 1601px) {
    .product-container {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        padding: 0 12px;
    }

    .middle-navbar {
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;
    }
}

:root {
    --marquee-width: 100vw;
    --marquee-height: ;
    /* --marquee-elements: 12; */
    /* defined with JavaScript */
    --marquee-elements-displayed: 5;
    --marquee-element-width: calc(var(--marquee-width) / var(--marquee-elements-displayed));
    --marquee-animation-duration: calc(var(--marquee-elements) * 3s);
}

.marquee {
    margin: 0 auto;
    max-width: 100%;
    width: var(--marquee-width);
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 00px;
    align-items: center;
    display: flex;
}

.marquee:before,
.marquee:after {

    background: linear-gradient(90deg, #ece6df 23.96%, #fff0 100%);
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 120px;
    z-index: 1;
}

.marquee:before {
    left: 0;

}

.marquee:after {
    right: -25px;
    transform: rotate(-180deg);
}

.marquee__content {
    width: 100%;
    display: flex;
    line-height: 30px;
    animation: marquee 30s linear infinite forwards;
    animation-iteration-count: infinite;
}

.marquee__content:hover {
    animation-play-state: paused;
}

.list-inline {
    display: flex;
    justify-content: space-around;

    list-style: none;
    padding: 0;
    margin: 0;
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width: 600px) {
    .marquee {
        border-radius: 0;
        margin-left: 2px;
        margin-right: -5px;
    }

    .marquee:before,
    .marquee:after {
        width: 70px;
    }
}

.think-animation-box {
    margin: auto;
    position: absolute;
    top: -20px;
    overflow: hidden;
    height: 292px;
    width: 320px;
    right: -20px;
    left: -32px;
}

.think-animation-box .hearts_wrapper {
    width: 100%;
    height: 268px;
    margin: 0 auto;
}

.think-animation-box .hearts_wrapper .heart {
    text-decoration: none;
    width: 100%;
    display: block;
    position: relative;
    color: #222;
    border: 1px solid #222;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1px 12px;
    border-radius: 10px;
    position: absolute;
    background: #ffbe82;
    font-size: 13px;
    font-family: 'SF UI Text Semibold';

}

.think-animation-box .hearts_wrapper .heart:nth-child(1) {
    right: 38px;
    top: 66px;
}

.think-animation-box .hearts_wrapper .heart:nth-child(2) {
    right: 73px;
    top: 14px;
}

.think-animation-box .hearts_wrapper .heart:nth-child(3) {
    left: 88px;
    top: 9px;
}

.budget-section-card-url .think-animation-box {
    top: -24px;
    left: -48px;
}

@keyframes rotate1 {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes parent-anim {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sec-head {
    font-family: "SF UI Text Semibold";
    font-size: 24px;
    line-height: 1.5;
    color: #222;
    text-align: left;
    padding-bottom: 19px;
    margin: 0px;
    display: flex;
}

.budget-popup-box .sec-head {
    text-align: center;
    padding-bottom: 0px;
}

.budget-popup-box .sub-sec-heading {
    text-align: center;
    padding-bottom: 0px;
}

/* End animations*/
@media only screen and (max-width: 600px) {
    .mt-block {
        display: block;
    }

    .think-animation-box {
        left: -55px;
        top: -24px;
    }

    .budget-popup-box .think-animation-box {
        left: -46px !important;
        top: -27px !important;
    }

    .heart:nth-child(1) {
        right: 60px !important;
    }

    .heart:nth-child(2) {
        right: 87px !important;
        top: 33px !important;
    }

    .heart:nth-child(3) {
        left: 88px !important;
        top: 4px !important;
    }
}

.ai-strip-section {

    display: flex;
    padding: 17px 32px;
    border-radius: 16px !important;
    box-shadow: 0 1px 3px rgba(36, 39, 44, 0.15);
    background: linear-gradient(to bottom, #430e45 0%, #4d3081 100%);
    align-items: center;
    justify-content: center;


}

.ai-strip-section img {
    height: 62px;
}

.ai-strip-section p {
    font-size: 18px;
    margin-left: 16px;
    font-family: "SF UI Text Regular";
    color: #fff;
}

.text-panel-sec {
    background-color: #f7f7f7;
}

.text-panel-sec h2 {
    font-size: 1.75rem;
    line-height: 1.4;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 1rem;
    font-family: 'SF UI Text Semibold';
    letter-spacing: -1px;
    color: #505050;

}

.text-panel-sec p {
    margin-bottom: 1rem !important;
    font-size: inherit;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
    color: #313131;
    margin-bottom: 1rem !important;
    font-size: 14px;
    letter-spacing: -0.1px;

}

.text-panel-sec p a {
    cursor: pointer;
}

.text-panel-sec p:last-child {
    margin-bottom: 0rem;
}
@media screen and (max-width: 1380px) {
    .product-img{
        height: 142px;
    }
    s{
        white-space: nowrap;
    }
    .dis-off{
        font-size: 10px !important;
    }
}
@media only screen and (max-width: 600px) {

    .text-panel-sec h2 {
        font-size: 1.25rem;
    }
}

.aboy-us-sec h2,
h3 {
    font-size: 16px;
    font-family: 'SF UI Text Semibold';
}

.aboy-us-sec h3 {
    font-size: 16px;
    font-family: 'SF UI Text Semibold';
}

.aboy-us-sec p,
li {
    font-size: 14px;
    color: #313131;
    line-height: 1.6;
}

.aboy-us-sec ul {
    margin-bottom: 0;
}

.ql-align-center {
    text-align: center !important;
}

.ql-align-left {
    text-align: left !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-justify {
    text-align: justify !important;
}

.ai-strip-section p {
    font-size: 13px;
}